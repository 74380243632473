<template>
  <v-app class="home">
    <div class="app-wrapper">
      <sidebar ref="sidebar" />
      <v-main>
        <div class="main-content">
          <slot name="content"></slot>
        </div>
      </v-main>
      <topbar ref="topbar" />
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'LocationsView'
}
</script>

<style lang="scss" scoped>
@import '@/style/variables';
.app-wrapper {
  display: flex;
  height: 100%;
}

.main-content {
  /* flex-grow: 1; */
  height: 100%;
  margin-left: $sidebar-width;
  margin-top: 0px;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  display: none;
}

.home {
  scrollbar-width: none;
}
</style>
