<template>
  <v-dialog
    v-model="alwaysOpen"
    :fullscreen="isMobile"
    width="80%"
    max-width="512px"
    @click:outside="close"
  >
    <v-card class="form-dialog form-dialog--unsaved">
      <h1 class="form-dialog__title">Changes won't be saved</h1>

      <p class="form-dialog__subtitle">
        You are about to close the {{ flow }} without saving. Are you sure you
        want to continue?
      </p>

      <v-row class="form-dialog__button-wrap">
        <v-col cols="6">
          <v-btn class="pkmn-button" outlined color="danger" @click="close">
            Back
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            class="pkmn-button pkmn-button--confirm pkmn-button--has-border"
            color="danger"
            @click="confirm"
          >
            OK
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormConfirmUnsave',
  props: {
    flow: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      alwaysOpen: true,
      mobileBreakpoint: 767
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.finally()
    },
    confirm() {
      this.$emit('confirm')
      this.finally()
    },

    /**
     * this function is always called
     */
    finally() {
      this.isOpening = false
      this.$emit('finally')
    }
  },
  mounted() {
    // if user press Escape, close the dialog and clear all data
    // ONLY works if a component is NOT attacted to the view as a default (use v-if to decide whether a component is attacted or not)
    // Otherwise, all 'close' listener of components existing in a view will be notified
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.close()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
.pkmn-button--confirm {
  color: $color-white;
}
</style>
